function timestampToDateTimeModal(date) {
    if (!date) {
        return null;
    }
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getDate().toString().padStart(2, '0');
    const hours = originalDate.getHours().toString().padStart(2, '0');
    const mins = originalDate.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${mins}`;
}

export default timestampToDateTimeModal;
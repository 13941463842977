import styled from 'styled-components';
import Sync from "./Sync";
import Remove from "./Remove";
import State from "./State";
import timestampToDateTime from "../../functions/dateTimeFormat";
import Members from "./Members";

const CardStyle = styled.div`
  background-color: white;
  min-width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  border: var(--color-secondary) solid 1px;
`

const TitleContainer = styled.div`
  font-size: 0.8rem;
  background-color: var(--color-secondary);
  color: white;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  padding: 4px;
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.8rem;
  padding: 2px;
  margin-top: 10px;
`

const ActionsContainer = styled.div`
  padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 7px;
`

function SegmentItem({segment, connectors}) {
    return (
        <CardStyle>
            <TitleContainer>{segment.name}</TitleContainer>
            <HeaderContainer>
                {connectors !== null &&
                    <div>
                        Connector: {connectors.get(segment.connectorId)}
                    </div>
                }
                <div>
                    Provider: {segment.provider}
                </div>
                <div>
                    State: <State state={segment.state}/>
                </div>
                <div>
                    Last Sync: {timestampToDateTime(segment.lastSync)}
                </div>
                <ActionsContainer>
                    <Sync segment={segment}></Sync>
                    <Members segment={segment}></Members>
                    <Remove segment={segment}></Remove>
                </ActionsContainer>
            </HeaderContainer>
        </CardStyle>
    )
}


export default SegmentItem;

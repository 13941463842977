import {useContext, useState} from "react";
import Button from "../../ui/core/Button";
import AddFanModal from "./AddFanModal";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import FanForm from "./FanForm";
import axios from "axios";
import {FanContext} from "./FanContext";
import {useAuth} from "../../authContext";

function AddFan() {
    const auth = useAuth();
    const updateFans = useContext(FanContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const fanObj = {
        firstName: null,
        lastName: null,
        email: "",
        birthDay: null,
        signedUpAt: "",
        active: false,
        acceptsPrivacyPolicy: false,
        acceptsCommercialInfo: false,
        acceptsCommercialPartners: false,
    }

    const data = {
        firstName: null,
        lastName: null,
        email: "",
        birthDay: null,
        active: false,
        signedUpAt: "",
        acceptsPrivacyPolicy: false,
        acceptsCommercialInfo: false,
        acceptsCommercialPartners: false,
    }

    const [fan, setFan] = useState({...fanObj});
    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);

        data.firstName = fan.firstName;
        data.lastName = fan.lastName;
        data.email = fan.email;

        if (fan.birthDay === "") {
            data.birthDay = null;
        } else {
            data.birthDay = fan.birthDay
        }

        if (fan.signedUpAt === "") {
            data.signedUpAt = null;
        } else {
            data.signedUpAt = fan.signedUpAt
        }

        data.active = fan.active;
        data.acceptsPrivacyPolicy = fan.acceptsPrivacyPolicy;
        data.acceptsCommercialInfo = fan.acceptsCommercialInfo;
        data.acceptsCommercialPartners = fan.acceptsCommercialPartners;

        await axios.post("fan/" +auth.user.tenant + "/system-fans", data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new fan", type: "success"});
                    setIsModal(false);
                    updateFans(0, true);
                } else {
                    setError("An error occurred while trying to save the fan");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the fan");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add fan
                        </Button>
                    </div>
                    <AddFanModal
                        title="Add new fan"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <FanForm
                            {...{
                                addToast: addToast,
                                loading,
                                fan: fan,
                                setFan: setFan,
                                error,
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddFanModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddFan;

import {Button, Field, Message} from "../../ui/core";
import Select from "react-select";
import axios from "axios";
import {useAuth} from "../../authContext";
import {useEffect, useState} from "react";

let connectorMap = new Map();

function SegmentForm({
                         addToast,
                         loading,
                         onSubmit,
                         segment,
                         setSegment,
                         provider,
                         setProvider,
                         connectorId,
                         setConnectorId,
                         bookmarkId,
                         setBookmarkId,
                         error,
                     }) {

    const auth = useAuth();
    const [connectors, setConnectors] = useState([]);
    const [bookmarks, setBookmarks] = useState([]);
    useEffect(() => {
        axios.get('/integrations/' + auth.user.tenant + '/connectors?type=marketing').then(
            res => {
                let connectorsOptions = []
                res.data.items.map((connector) => {
                    connectorsOptions.push({
                        value: connector.id,
                        label: connector.name,
                    })
                    connectorMap.set(connector.id, connector.provider)
                })
                setConnectors(connectorsOptions)
            },
            err => {
                console.log(err)
            }
        )

        axios.get('/analytics/' + auth.user.tenant + '/bookmarks').then(
            res => {
                let bookmarksOptions = []
                res.data.items.map((bookmark) => {
                    bookmarksOptions.push({
                        value: bookmark.id,
                        label: bookmark.name,
                    })
                })
                setBookmarks(bookmarksOptions)
            },
            err => {
                console.log(err)
            }
        )
    }, []);

    const onChange = (e) => {
        setSegment({
            ...segment,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="Name" id="name-title">
                    <input
                        type="text"
                        value={segment.name}
                        onChange={onChange}
                        name="name"
                        id="name-title"
                    />
                </Field>
                <Field labelText="Description" id="description-title">
                    <input
                        type="text"
                        value={segment.description}
                        onChange={onChange}
                        name="description"
                        id="description-title"
                    />
                </Field>
                <Field labelText="Bookmark" id="bookmark">
                    <Select
                        options={bookmarks} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                        }),
                    }}
                        onChange={
                            (value) => {
                                setBookmarkId(value.value)
                            }
                        }
                        value={bookmarkId.value}
                        placeholder="Select bookmark"
                        name="bookmark"
                    />
                </Field>
                <Field labelText="Connector" id="connector">
                    <Select
                        options={connectors} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                        }),
                    }}
                        onChange={
                            (value) => {
                                setConnectorId(value.value)
                                setProvider(connectorMap.get(value.value))
                            }
                        }
                        value={connectorId.value}
                        placeholder="Select connector"
                        name="connector"
                    />
                </Field>
                <div>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                <Message text={error} type="error"/>
            </form>

        </>

    );
}

export default SegmentForm;

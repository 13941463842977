import {useContext, useState} from "react";
import Button from "../../../ui/core/Button";
import InviteUserModal from "./InviteUserModal.js";
import {ToasterContext} from "../../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import {UserContext} from "./UserContext"
import {useAuth} from "../../../authContext";
import InviteUserForm from "./InviteUserForm";

function InviteUser() {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        email: "",
    });

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        await axios.post('accounts/' + auth.user.tenant + "/users/invite", data,).then(
            res => {
                setLoading(false);
                if (res.status === 200) {
                    addToast({text: "Email sent successfully", type: "success"});
                    setIsModal(false);
                } else {
                    setError("Error 1");
                }
            }
        ).catch(
            err => {
                setError("Error 2");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Invite user
                        </Button>
                    </div>
                    <InviteUserModal
                        title="Invite user"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <InviteUserForm
                            {...{
                                loading,
                                data: data,
                                setData: setData,
                                error,
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </InviteUserModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default InviteUser;

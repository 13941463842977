import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FaTrash} from 'react-icons/fa';
import {useAuth} from "../../../authContext";
import axios from "axios";
import InviteUser from "./InviteUser";
import {UserContext} from "./UserContext";
import {Message} from "../../../ui/core";

const UserItem = ({user, onDelete}) => {
    return (
        <UserCard>
            <UserInfo>
                <strong>{user.username}</strong>
                <span>{user.email}</span>
                <span>{user.role}</span>
            </UserInfo>
            <DeleteButton onClick={() => onDelete(user.id)}>
                <FaTrash/>
            </DeleteButton>
        </UserCard>
    );
};

const UserList = ({
                      users, onDelete
                  }) => {

    const updateUsers = useContext(UserContext);
    return (
        <Container>
            <UserContext.Provider value={updateUsers}>
                {users.map((user) => (
                    <UserItem key={user.id} user={user} onDelete={onDelete}/>
                ))}
                <InviteUser/>
            </UserContext.Provider>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const UserCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  strong {
    font-size: 18px;
    margin-bottom: 4px;
  }

  span {
    color: #666;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #ff4d4f;

  &:hover {
    color: #d63031;
  }
`;

const UserManagement = () => {
    const auth = useAuth();
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const updateUsers = () => {
        setError(null)
        axios
            .get('accounts/' + auth.user.tenant + '/users')
            .then((res) => {
                setUsers(res.data.items);
            })
            .catch((err) => {
                setError('Error updating users')
            });
    };

    useEffect(() => {
        updateUsers();
    }, []);


    const handleDelete = (userId) => {
        setSuccess(null)
        setError(null)
        axios
            .delete('accounts/' + auth.user.tenant + '/users/' + userId)
            .then((res) => {
                if (res.status === 200){
                    setSuccess("user deleted successfully")
                    updateUsers();
                } else {
                    setError('error deleting user')
                }

            })
            .catch((err) => {
                setError('error deleting user')
            });
    };

    return (<UserContext.Provider value={updateUsers}>
        <Message text={error} type="error"/>
        <Message text={success} type="success"/>
        <UserList users={users} onDelete={handleDelete}/>
    </UserContext.Provider>)
};

export default UserManagement;

import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useAuth} from "../../authContext";
import {METABASE_URL} from "../../constants";
import AddBookmark from "./AddBookmark";
import Detach from "./Detach";
import GoBookmarks from "./GoBookmarks";

const Dashboard = () => {
    const auth = useAuth();
    const [filterParams, setFilterParams] = useState("");
    const [iframeUrl, setIframeUrl] = useState([]);

    useEffect(() => {
        axios.get('/analytics/' + auth.user.tenant + '/dashboard').then(
            res => {
                setIframeUrl(res.data.url)
            },
            err => {
                console.log(err)
            }
        )
    }, []);

    useEffect(() => {
        const receiveMessage = (event) => {
            if (event.origin !== METABASE_URL)
                return;

            if (!event.data?.metabase)
                return;

            if (event.data.metabase.type !== 'location')
                return;

            const newFilterParams = event.data.metabase.location.search;
            setFilterParams(newFilterParams.replace(/tab=([^&]+)&?/g, ""));
        };

        window.addEventListener('message', receiveMessage);

        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, [filterParams]);

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'left', paddingLeft: '16px'}}>
                <Detach url={iframeUrl}/>
                <AddBookmark params={filterParams} setParams={setFilterParams}/>
                <GoBookmarks params={filterParams} setParams={setFilterParams}/>
            </div>
            <div style={{height: "90vh"}}>
                <iframe
                    style={{width: "100%", height: "100%", frameborder: "0", allowtransparency: "true"}}
                    src={iframeUrl}
                >
                </iframe>
            </div>
        </div>
    );
};

export default Dashboard;
import React, {useEffect} from "react";
import {useAuth} from "../../authContext";


function PricingTable() {
    const auth = useAuth();
    useEffect(() => {
        const script = document.createElement("script")
        script.src =
            "https://js.stripe.com/v3/pricing-table.js"
        script.async = true
        // script.integrity =
        //     "sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        //
        // script.crossOrigin = "anonymous"
        document.body.appendChild(script)

        return () => {
            // clean up the script when the component in unmounted
            document.body.removeChild(script)
        }
    }, [])

    return (
        <>
            <stripe-pricing-table pricing-table-id="prctbl_1QDW8vC0KeIlTob0tiY15OeO"
                                  publishable-key="pk_test_51NQu2VC0KeIlTob07Jh9mxMiLwZSg2qutpY3kFnuDygviKxkPcuaAIudS0yWIbbIG098bMX3qipd1oucu98y6Uts00Bl12kz79"
                                  client-reference-id={auth.user.tenant}
                                  customer-email={auth.user.email}
            >
            </stripe-pricing-table>
        </>
    );
}

export default PricingTable;
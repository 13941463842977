import styled from 'styled-components';
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../authContext";
import axios from "axios";
import {Message} from "../ui/core";

const LogoStyle = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    padding-top: 5%;
`

const LoginFormStyle = styled.div`
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    width: 30%;
    height: 95%;
    margin: 40px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.4rem;
    max-width: 100%;
`

const RowInput = styled.input`
    & {
        width: 80%;
        box-sizing: border-box;
        border: none;
        font-size: 0.95rem;
        padding-left: 1.5rem;
        padding-bottom: 1rem;
        box-shadow: inset 0px -3px 0px 0px rgba(9, 8, 8, 0.2);
        transition: box-shadow 0.2s ease-in;
        -webkit-appearance: none;
        border-radius: 0px;
    }

    &:focus {
        box-shadow: inset 0px -3px 0px 0px var(--color-primary);
        outline: none;
    }

    &::-webkit-input-placeholder {
        opacity: 1;
        transition: opacity 0.25s ease-out;
        color: rgba(9, 5, 5, 0.34);
    }

    &:hover::-webkit-input-placeholder,
    &:focus::-webkit-input-placeholder {
        opacity: 0;
    }
`

const RowLabel = styled.label`
    align-self: start;
    padding-left: 4.5rem;
    padding-bottom: 0.5rem;
    color: #555;
    font-size: 1rem;
`

const RowButton = styled.button`
    & {
        border-radius: 25px;
        width: 80%;
        height: 40px;
        font-size: 1.3rem;
        color: white;
        font-weight: 700;
        background: linear-gradient(90deg, var(--color-secondary) 0%, var(--color-secondary-light) 100%);
        border: 2px solid;
        border-color: var(--color-primary);
        cursor: pointer;
        transition: opacity 0.25s ease-out;
        margin-top: 1%;
    }

    &:hover {
        opacity: 0.8;
    }
`

const SignUpStyle = styled.div`
    text-align: center;
    padding-top: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
`

export const RegisterForm = () => {
    const history = useHistory();
    const location = useLocation();
    const [register, setRegister] = useState({
        email: "",
    });
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const auth = useAuth();

    useEffect(() => {
        if (auth.user) history.replace(location.state ? location.state.from : '/');
    }, [auth.user])

    const onChange = (e) => {
        setRegister((prevRegister) => ({
            ...prevRegister,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post('/accounts/register', register).then(async (resp) => {
            if (resp.status === 200) {
                setSuccess("Email sent successfully")
            } else {
                setError(resp.data.error.details.message);
            }
        }).catch(() => {
            setError("Error registering new account");
        });
    }

    return (
        <>
            <Message text={error} type="error"/>
            <Message text={success} type="success"/>
            <LoginFormStyle>
                <form onSubmit={handleSubmit}>
                    <FormHeader title="New Account"/>
                    <Form register={register} onChange={onChange}/>
                    <SignIn/>
                </form>
            </LoginFormStyle>
        </>
    )
}

const FormHeader = () => (
    <a href={'/'}>
        <LogoStyle src={'logo.png'}/>
    </a>);

const Form = props => {
    return (
        <div>
            <FormInput value={props.register.email}
                       onChange={props.onChange}
                       description="Email"
                       placeholder="Enter your email"
                       type="email" name="email"/>
            <FormButton title="Sign Up"/>
        </div>
    )
};

const FormButton = props => (
    <Row>
        <RowButton type="submit">{props.title}</RowButton>
    </Row>
);

const FormInput = props => (
    <Row>
        <RowLabel>{props.description}</RowLabel>
        <RowInput type={props.type}
                  placeholder={props.placeholder}
                  value={props.value}
                  onChange={props.onChange}
                  name={props.name}
                  id={props.name}
                  maxLength={props.maxLength}
        />
    </Row>
);

const SignIn = () => (
    <SignUpStyle>
        <label>Already registered? </label>
        <a href={'/login'}>Sign In</a>
    </SignUpStyle>
);

import styled, { css } from "styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 9999;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      pointer-events: all;
    `};
`;

const ModalContent = styled.div`
  background-color: white;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  max-width: calc(100% - 60px);
  max-height: calc(100% - 60px);
  position: relative;
  overflow: hidden;

  .title {
    margin-top: 0;
    margin-bottom: 40px;
    color: var(--color-secondary);
  }

  .scroller {
    position: relative;
    padding: 30px;
    overflow: hidden;
    max-height: calc(100vh - 60px);

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;

function EditFanModal(props) {
  return (
      <ModalWrapper show={props.show}>
        <ModalContent width={props.width} height={props.height}>
          <div className="scroller">
            <h3 className="title">Edit fan</h3>
            {props.children}
            <div className="close" onClick={props.close}>
              x
            </div>
          </div>
        </ModalContent>
      </ModalWrapper>
  );
}

export default EditFanModal;

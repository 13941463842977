import React, {useState} from "react";
import axios from "axios";
import styled from "styled-components";
import {useAuth} from "../../authContext";
import AddFan from "../fans/AddFan";
import MembershipsTable from "./MembershipsTable";
import ImportMemberships from "./ImportMemberships";
import {MembershipContext} from "./MembershipContext";
import AddMembership from "./AddMembership";

const FlexContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
    flex-grow: 0;
    width: auto;
`;


function MembershipsComponent() {
    const auth = useAuth();
    const [memberships, setMemberships] = useState([]);
    const [pagination, setPagination] = useState({
        offset: 0,
        limit: 10,
        moreItems: false,
        totalItems: 0
    });

    const updateMemberships = (offset = 0, limit = 10) => {
        axios
            .get('membership/' + auth.user.tenant + '/memberships?limit=' + limit + '&offset=' + offset)
            .then((res) => {
                setMemberships(res.data.items);
                setPagination({
                    offset: res.data.offset,
                    limit: res.data.limit,
                    moreItems: res.data.moreItems,
                    totalItems: res.data.totalItems
                })
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <MembershipContext.Provider value={updateMemberships}>
            <FlexContainer>
                <AddMembership/>
                <ImportMemberships/>
            </FlexContainer>
            <MembershipsTable memberships={memberships} pagination={pagination}/>
        </MembershipContext.Provider>
    );
}

export default MembershipsComponent;

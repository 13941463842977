import {Button, Field, Message} from "../../ui/core";

function ImportFanForm({ onFile, onSubmit, loading, error }) {
    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="Upload Fan CSV" id="file-upload">
                    <input
                        type="file"
                        accept=".csv"
                        onChange={onFile}
                        name="file"
                        id="file-upload"
                    />
                </Field>
                <div>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                {error && <Message text={error} type="error" />}
            </form>
        </>
    );
}

export default ImportFanForm;

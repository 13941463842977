import {useContext, useState} from "react";
import Button from "../../ui/core/Button";
import AddSegmentModal from "./AddSegmentModal.js";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import SegmentForm from "./SegmentForm";
import axios from "axios";
import {SegmentContext} from "./SegmentContext"
import {useAuth} from "../../authContext";

function AddSegment() {
    const auth = useAuth();
    const updateSegments = useContext(SegmentContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const segmentObj = {
        name: "",
        description: "",
        bookmarkId: "",
        connectorId: "",
    }

    const config = {
        discriminator: "",
        name: "",
        description: "",
    }

    const data = {
        name: "",
        description: "",
        config: config,
    }

    const [provider, setProvider] = useState('')
    const [bookmarkId, setBookmarkId] = useState('')
    const [connectorId, setConnectorId] = useState('')
    const [segment, setSegment] = useState({...segmentObj});
    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        data.name = segment.name;
        data.description = segment.description;
        data.connectorId = connectorId;
        data.bookmarkId = bookmarkId;
        data.config.discriminator = provider;
        data.config.name = segment.name;
        data.config.description = segment.description;

        await axios.post('/marketing/' + auth.user.tenant + '/segments', data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new segment", type: "success"});
                    setIsModal(false);
                    updateSegments();
                } else {
                    setError("An error occurred while trying to save the segment");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the segment");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add segment
                        </Button>
                    </div>
                    <AddSegmentModal
                        title="Add new segment"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <SegmentForm
                            {...{
                                addToast: addToast,
                                loading,
                                segment: segment,
                                setSegment: setSegment,
                                provider: provider,
                                setProvider: setProvider,
                                connectorId: connectorId,
                                setConnectorId: setConnectorId,
                                bookmarkId: bookmarkId,
                                setBookmarkId: setBookmarkId,
                                error,
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddSegmentModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddSegment;

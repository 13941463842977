import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import styled from "styled-components";
import {useState} from "react";
import {useAuth} from "../../authContext";
import MembersModal from "./MembersModal";
import {FaEye} from "react-icons/fa6";

const ActionButton = styled.button`
  & {
    background-color: #3d413d;
    color: white;
    border-radius: 10px;
    border: 0px;
    padding: 5px;
    width: 20%;
    max-width: 80px;
  }

  &:hover {
    background-color: rgb(105, 103, 102);
    opacity: 0.5;
  }
`


function Members(props) {
    const auth = useAuth();
    const [members, setMembers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        await axios.get('/marketing/' + auth.user.tenant + '/segments/' + props.segment.id + '?members=true').then(
            res => {
                if (res.status === 200) {
                    setMembers(res.data.members);
                    openModal();
                } else {
                    addToast({text: "Error getting segment members", type: "error"});
                }
            }
        ).catch(
            err => {
                addToast({text: "Error getting segment members", type: "error"});
            })
    };

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <ActionButton onClick={onSubmit.bind(this, addToast)}>
                        <FaEye/>
                    </ActionButton>
                    <MembersModal
                        title="List of members"
                        show={isModalOpen}
                        close={closeModal.bind(this)}
                    >
                        <>
                        {members.map((member, index) => (
                            <div key={index}>
                                {member}
                            </div>
                        ))}
                        </>
                    </MembersModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default Members;

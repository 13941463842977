import {useContext, useState} from "react";
import Button from "../../ui/core/Button";
import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import {useAuth} from "../../authContext";
import AddMembershipModal from "./AddMembershipModal";
import AddMembershipForm from "./AddMembershipForm";
import {MembershipContext} from "./MembershipContext";

function AddMembership() {
    const auth = useAuth();
    const updateMemberships = useContext(MembershipContext);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const fanObj = {
        membershipId: "",
        type: "",
        firstName: null,
        lastName: null,
        email: "",
        birthDay: null,
        signedUpAt: "",
        entryDate: "",
        expirationDate: "",
        active: false,
        acceptsPrivacyPolicy: false,
        acceptsCommercialInfo: false,
        acceptsCommercialPartners: false,
    }

    const data = {
        membershipId: "",
        type: "",
        firstName: null,
        lastName: null,
        email: "",
        birthDay: null,
        active: false,
        signedUpAt: "",
        entryDate: "",
        expirationDate: "",
        acceptsPrivacyPolicy: false,
        acceptsCommercialInfo: false,
        acceptsCommercialPartners: false,
    }

    const [membership, setMembership] = useState({...fanObj});
    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);

        data.membershipId = membership.membershipId;
        data.type = membership.type;
        data.firstName = membership.firstName;
        data.lastName = membership.lastName;
        data.email = membership.email;

        if (membership.birthDay === "") {
            data.birthDay = null;
        } else {
            data.birthDay = membership.birthDay
        }

        if (membership.signedUpAt === "") {
            data.signedUpAt = null;
        } else {
            data.signedUpAt = membership.signedUpAt
        }

        if (membership.entryDate === "") {
            data.entryDate = null;
        } else {
            data.entryDate = membership.entryDate
        }

        if (membership.expirationDate === "") {
            data.expirationDate = null;
        } else {
            data.expirationDate = membership.expirationDate
        }

        data.active = membership.active;
        data.acceptsPrivacyPolicy = membership.acceptsPrivacyPolicy;
        data.acceptsCommercialInfo = membership.acceptsCommercialInfo;
        data.acceptsCommercialPartners = membership.acceptsCommercialPartners;

        await axios.post("membership/" + auth.user.tenant + "/memberships", data,).then(
            res => {
                setLoading(false);
                if (res.status === 201) {
                    addToast({text: "Successfully created a new membership", type: "success"});
                    setIsModal(false);
                    updateMemberships(0, true);
                } else {
                    setError("An error occurred while trying to save the membership");
                }
            }
        ).catch(
            err => {
                setError("An error occurred while trying to save the membership");
                setLoading(false);
            })
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>
                            Add Membership
                        </Button>
                    </div>
                    <AddMembershipModal
                        title="Add new membership"
                        show={isModal}
                        close={modalShow.bind(this, false)}
                    >
                        <AddMembershipForm
                            {...{
                                addToast: addToast,
                                loading,
                                membership: membership,
                                setMembership: setMembership,
                                error,
                            }}
                            onSubmit={onSubmit.bind(this, addToast)}
                        />
                    </AddMembershipModal>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default AddMembership;

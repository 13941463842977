import React from 'react';
import {Button, Field, Message} from '../../ui/core';

function AddMembershipForm({
                     addToast,
                     loading,
                     onSubmit,
                     membership,
                     setMembership,
                     error,
                 }) {
    const onChange = (e) => {
        const {name, value, type, checked} = e.target;

        const newValue = type === 'checkbox' ? checked : value;

        setMembership({
            ...membership,
            [name]: newValue,
        });
    };

    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="ID" id="membership-id-title">
                    <input
                        type="text"
                        value={membership.membershipId}
                        onChange={onChange}
                        name="membershipId"
                        id="membership-id-title"
                    />
                </Field>
                <Field labelText="Type" id="type-title">
                    <input
                        type="text"
                        value={membership.type}
                        onChange={onChange}
                        name="type"
                        id="type-title"
                    />
                </Field>
                <Field labelText="First name" id="first-name-title">
                    <input
                        type="text"
                        value={membership.firstName}
                        onChange={onChange}
                        name="firstName"
                        id="first-name-title"
                    />
                </Field>
                <Field labelText="Last name" id="last-name-title">
                    <input
                        type="text"
                        value={membership.lastName}
                        onChange={onChange}
                        name="lastName"
                        id="last-name-title"
                    />
                </Field>
                <Field labelText="Email" id="email-title">
                    <input
                        type="text"
                        value={membership.email}
                        onChange={onChange}
                        name="email"
                        id="email-title"
                    />
                </Field>
                <Field labelText="Birthday" id="birthday-title">
                    <input
                        type="date"
                        value={membership.birthDay}
                        onChange={onChange}
                        name="birthDay"
                        id="birthday-title"
                    />
                </Field>
                <Field labelText="Signed up at" id="signed-up-title">
                    <input
                        type="datetime-local"
                        value={membership.signedUpAt}
                        onChange={onChange}
                        name="signedUpAt"
                        id="signed-up-title"
                    />
                </Field>
                <Field labelText="Entry date" id="entry-date-title">
                    <input
                        type="datetime-local"
                        value={membership.entryDate}
                        onChange={onChange}
                        name="entryDate"
                        id="entry-date-title"
                    />
                </Field>
                <Field labelText="Expiration date" id="expiration-date-title">
                    <input
                        type="datetime-local"
                        value={membership.expirationDate}
                        onChange={onChange}
                        name="expirationDate"
                        id="expiration-date-title"
                    />
                </Field>
                <Field labelText="Active" id="active-title">
                    <input
                        type="checkbox"
                        checked={membership.active}
                        onChange={onChange}
                        name="active"
                        id="active-title"
                    />
                </Field>
                <Field labelText="Accepts privacy policy" id="privacy-policy-title">
                    <input
                        type="checkbox"
                        checked={membership.acceptsPrivacyPolicy}
                        onChange={onChange}
                        name="acceptsPrivacyPolicy"
                        id="privacy-policy-title"
                    />
                </Field>
                <Field labelText="Accepts commercial info" id="commercial-info-title">
                    <input
                        type="checkbox"
                        checked={membership.acceptsCommercialInfo}
                        onChange={onChange}
                        name="acceptsCommercialInfo"
                        id="commercial-info-title"
                    />
                </Field>
                <Field labelText="Accepts commercial partners" id="commercial-partners-title">
                    <input
                        type="checkbox"
                        checked={membership.acceptsCommercialPartners}
                        onChange={onChange}
                        name="acceptsCommercialPartners"
                        id="commercial-partners-title"
                    />
                </Field>
                <div>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                <Message text={error} type="error"/>
            </form>
        </>
    );
}

export default AddMembershipForm;
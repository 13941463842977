import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import React, {useContext, useEffect, useState} from 'react';
import {useTable, usePagination, useSortBy} from 'react-table';
import styled from 'styled-components';
import {IoMdArrowDropup, IoMdArrowDropdown} from 'react-icons/io';
import timestampToDate from "../../functions/date";
import {useAuth} from "../../authContext";
import axios from "axios";
import {FaEdit, FaTrash} from "react-icons/fa";
import timestampToDateTimeModal from "../../functions/dateTimeModalFormat";
import {MembershipContext} from "./MembershipContext";
import EditMembershipModal from "./EditMembershipModal";
import EditForm from "./EditForm";

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    border: 1px solid #ccc;
`;

const StyledTh = styled.th`
    border: 1px solid var(--color-secondary);
    padding: 8px;
    text-align: left;
    color: white;
    background-color: var(--color-secondary);
`;

const StyledTd = styled.td`
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    font-size: 14px;
`;

const PageNavigation = styled.div`
    display: flex;
    align-items: center;
`;

const PageButton = styled.button`
    padding: 4px 8px;
    margin: 0 2px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #ddd;
    }
`;

const PageSizeSelect = styled.select`
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
`;

const EditIcon = styled(FaEdit)`
    cursor: pointer;
    color: rgba(9, 5, 5, 0.9);
    margin-right: 5px;
    font-size: 15px;

    &:hover {
        color: #c78c1c;
    }
`;

const DeleteIcon = styled(FaTrash)`
    cursor: pointer;
    color: rgba(9, 5, 5, 0.9);
    font-size: 15px;

    &:hover {
        color: #e31820;
    }
`;

const ResponsiveDataTable = ({memberships: memberships, pagination: pagination}) => {
    const auth = useAuth();
    const updateMemberships = useContext(MembershipContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFan, setSelectedFan] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEditClick = (fanData) => {
        setSelectedFan(fanData);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handlePageChange = (newOffset) => {
        updateMemberships(newOffset, pagination.limit);
    };

    const handleFirstPage = () => {
        handlePageChange(0);
    };

    const handlePreviousPage = () => {
        if (pagination.offset > 0) {
            const newOffset = Math.max(0, pagination.offset - pagination.limit);
            handlePageChange(newOffset);
        }
    };

    const handleNextPage = () => {
        if (pagination.moreItems) {
            const newOffset = pagination.offset + pagination.limit;
            handlePageChange(newOffset);
        }
    };

    const handleLastPage = () => {
        const totalPages = Math.ceil(pagination.totalItems / pagination.limit);
        const newOffset = (totalPages - 1) * pagination.limit;
        handlePageChange(newOffset);
    };

    const handleFormSubmit = async (addToast, e) => {
        e.preventDefault();
        setLoading(true);
        try {

            selectedFan.birthDay = timestampToDate(selectedFan.birthDay)
            selectedFan.signedUpAt = timestampToDateTimeModal(selectedFan.signedUpAt)
            selectedFan.entryDate = timestampToDateTimeModal(selectedFan.entryDate)
            selectedFan.expirationDate = timestampToDateTimeModal(selectedFan.expirationDate)

            const res = await axios.put('/membership/' + auth.user.tenant + '/memberships/' + selectedFan.id, selectedFan);
            if (res.status === 200) {
                addToast({text: "Membership successfully updated", type: "success"});
                setIsModalOpen(false);
                updateMemberships(pagination.offset, pagination.limit);
            } else {
                setError("An error occurred while trying to update the membership");
            }
        } catch (err) {
            setError("An error occurred while trying to update the membership");
        } finally {
            setLoading(false);
        }
    };

    const columns = React.useMemo(() => [
        {Header: 'ID', accessor: 'membershipId'},
        {Header: 'Type', accessor: 'type'},
        {Header: 'First Name', accessor: 'firstName'},
        {Header: 'Last Name', accessor: 'lastName'},
        {Header: 'Email', accessor: 'email'},
        {
            Header: 'Birthday', accessor: 'birthDay',
            Cell: ({value}) => timestampToDate(value),
        },
        {Header: 'Address', accessor: 'address'},
        {Header: 'Zip Code', accessor: 'zipCode'},
        {Header: 'City', accessor: 'city'},
        {Header: 'Country', accessor: 'country'},
        {Header: 'Phone', accessor: 'phone'},
        {Header: 'Gender', accessor: 'gender'},
        {
            Header: 'Entry Date', accessor: 'entryDate',
            Cell: ({value}) => timestampToDate(value),
        },
        {
            Header: 'Expiration Date', accessor: 'expirationDate',
            Cell: ({value}) => timestampToDate(value),
        }, {
            accessor: 'id',
            Cell: ({row}) => (
                <>
                    <ToasterContext.Consumer>
                        {({}) => (
                            <>
                                <EditIcon onClick={() => handleEditClick(row.original)}/>
                            </>
                        )}
                    </ToasterContext.Consumer>
                    <ToasterContext.Consumer>
                        {({addToast}) => (<DeleteIcon
                            onClick={async (e) => {
                                e.preventDefault();
                                await axios.delete('membership/' + auth.user.tenant + '/memberships/' + row.original.id).then(res => {
                                    if (res.status === 200) {
                                        addToast({text: "Membership deleted successfully", type: "success"});
                                        updateMemberships(pagination.offset, pagination.limit);
                                    } else {
                                        addToast({text: "Error deleting membership", type: "error"});
                                    }
                                }).catch(err => {
                                    addToast({text: "Error deleting membership", type: "error"});
                                })
                            }}
                        />)}
                    </ToasterContext.Consumer>
                </>

            ),
        },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state: {pageSize},
    } = useTable({
        columns,
        data: memberships,
        initialState: {pageIndex: 0, pageSize: pagination.limit},
    }, useSortBy, usePagination);

    useEffect(() => {
        updateMemberships(pagination.offset, pageSize);
    }, [pageSize]);

    return (
        <TableContainer>
            <StyledTable {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <StyledTh {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                        {column.isSorted ? (column.isSortedDesc ? <IoMdArrowDropdown/> :
                                            <IoMdArrowDropup/>) : ''}
                                    </span>
                            </StyledTh>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <StyledTd {...cell.getCellProps()}>{cell.render('Cell')}</StyledTd>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </StyledTable>
            <PaginationContainer>
                <PageNavigation>
                    <PageButton onClick={handleFirstPage} disabled={pagination.offset === 0}>
                        First
                    </PageButton>
                    <PageButton onClick={handlePreviousPage} disabled={pagination.offset === 0}>
                        Previous
                    </PageButton>
                    <PageButton onClick={handleNextPage} disabled={!pagination.moreItems}>
                        Next
                    </PageButton>
                    <PageButton onClick={handleLastPage} disabled={!pagination.moreItems}>
                        Last
                    </PageButton>
                </PageNavigation>
                <div>
                    Page{' '}
                    <strong>
                        {Math.floor(pagination.offset / pagination.limit) + 1} of {Math.ceil(pagination.totalItems / pagination.limit)}
                    </strong>
                </div>
                <PageSizeSelect
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map((size) => (
                        <option key={size} value={size}>
                            Show {size}
                        </option>
                    ))}
                </PageSizeSelect>
            </PaginationContainer>

            {isModalOpen && (
                <EditMembershipModal title="Edit Membership" show={isModalOpen} close={closeModal}>
                    <ToasterContext.Consumer>
                        {({addToast}) => (
                            <EditForm
                                loading={loading}
                                info={selectedFan}
                                setInfo={setSelectedFan}
                                error={error}
                                onSubmit={(e) => handleFormSubmit(addToast, e)}
                            />
                        )}
                    </ToasterContext.Consumer>
                </EditMembershipModal>
            )}
        </TableContainer>
    );
};

export default ResponsiveDataTable;

import {ToasterContext} from "../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import styled from "styled-components";
import {SegmentContext} from "./SegmentContext.js";
import {useContext} from "react";
import {useAuth} from "../../authContext";
import {FaSync} from "react-icons/fa";

const ActionButton = styled.button`
  & {
    background-color: dodgerblue;
    color: white;
    border-radius: 10px;
    border: 0px;
    padding: 5px;
    width: 20%;
    max-width: 80px;
  }

  &:hover {
    background-color: rgba(30, 144, 255, 0.53);
    opacity: 0.5;
  }
`


function Sync(props) {
    const auth = useAuth();
    const updateSegments = useContext(SegmentContext);

    const onSubmit = async (addToast, e) => {
        e.preventDefault();
        await axios.post('marketing/' + auth.user.tenant + '/segments/' + props.segment.id + '/sync').then(
            res => {
                if (res.status === 200) {
                    addToast({text: "Sync started successfully", type: "success"});
                    updateSegments();
                } else {
                    addToast({text: "Error syncing segment", type: "error"});
                }
            }
        ).catch(
            err => {
                addToast({text: "Error syncing segment", type: "error"});
            })
    };


    return (
        <ToasterContext.Consumer>
            {({addToast}) => (
                <>
                    <ActionButton onClick={onSubmit.bind(this, addToast)}>
                        <FaSync/>
                    </ActionButton>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default Sync;

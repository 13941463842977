import {Button, Field, Message} from "../../ui/core";
import Select from "react-select";
import axios from "axios";
import {useAuth} from "../../authContext";
import {useState} from "react";

const options = [
    {value: 'ONEBOX', label: 'OneBox'},
    {value: 'PRESTASHOP', label: 'PrestaShop'},
    {value: 'SHOPIFY', label: 'Shopify'},
    {value: 'FANID', label: 'FanID'},
    {value: 'MAILCHIMP', label: 'Mailchimp'},
    {value: 'DEMOMARKETING', label: 'DemoMarketing'},
    {value: 'MAUTIC', label: 'Mautic'},
    {value: 'DEMOSHOP', label: 'DemoShop'},
    {value: 'DEMOTICKETING', label: 'DemoTicketing'},
    {value: 'TWITTER', label: 'Twitter'},
    {value: 'DEMOSOCIAL', label: 'DemoSocial'},
    {value: 'SHOPGOOGLESHEETS', label: 'Shop Google Sheet'},
    {value: 'TICKETINGGOOGLESHEETS', label: 'Ticketing Google Sheet'},
    {value: 'MEMBERSHIPGOOGLESHEET', label: 'Membership Google Sheet'},

]

function ConnectorForm({
                           addToast,
                           loading,
                           onSubmit,
                           connector,
                           setConnector,
                           provider,
                           setProvider,
                           error,
                       }) {
    const auth = useAuth();
    const [testLoading, setTestLoading] = useState(false);
    const [testResult, setTestResult] = useState({
            "message": null,
            "type": null
        }
    );
    const data = {
        name: "",
        description: "",
        config: {
            enabled: false,
            autoSync: false,
            interval: 0,
        },
        providerConfig: {
            discriminator: "",
            clientSecret: "",
            apiUrl: "",
            auth: "",
            timeZone: "",
            apiKey: "",
            apiKeySecret: "",
            appId: "",
            url: "",
            fanObjectId: "",
            username: "",
            password: "",
            accessToken: "",
            accessTokenSecret: "",
            accountName: "",
            audienceId: "",
            user: "",
            serverPrefix: "",
            customerSheetId: "",
            orderSheetId: "",
            orderItemSheetId: "",
            ticketSheetId: "",
        },
    }

    const onChange = (e) => {
        const {name, value, type, checked} = e.target;
        const newValue = type === 'checkbox' ? checked : type === 'number' ? parseInt(value, 10) : value;
        setConnector((prevConnector) => ({
            ...prevConnector,
            [name]: newValue,
        }));
    };

    const testConnector = async (addToast, e) => {
        setTestResult({
            "message": null,
            "type": null
        })
        setTestLoading(true);

        data.name = connector.name;
        data.description = connector.description;
        data.config.autoSync = connector.autoSync;
        data.config.enabled = connector.enabled;
        data.config.interval = connector.interval;
        data.providerConfig.discriminator = provider;
        data.providerConfig.clientSecret = connector.clientSecret;
        data.providerConfig.apiUrl = connector.apiUrl;
        data.providerConfig.auth = connector.auth;
        data.providerConfig.timeZone = connector.timeZone;
        data.providerConfig.apiKey = connector.apiKey;
        data.providerConfig.apiKeySecret = connector.apiKeySecret;
        data.providerConfig.appId = connector.appId;
        data.providerConfig.url = connector.url;
        data.providerConfig.fanObjectId = connector.fanObjectId;
        data.providerConfig.username = connector.username;
        data.providerConfig.password = connector.password;
        data.providerConfig.accessToken = connector.accessToken;
        data.providerConfig.accessTokenSecret = connector.accessTokenSecret;
        data.providerConfig.accountName = connector.accountName;
        data.providerConfig.audienceId = connector.audienceId
        data.providerConfig.user = connector.user;
        data.providerConfig.serverPrefix = connector.serverPrefix;
        data.providerConfig.customerSheetId = connector.customerSheetId;
        data.providerConfig.orderSheetId = connector.orderSheetId;
        data.providerConfig.orderItemSheetId = connector.orderItemSheetId;
        data.providerConfig.ticketSheetId = connector.ticketSheetId;

        e.preventDefault();
        // setLoading(true);
        await axios.post('/integrations/' + auth.user.tenant + '/connectors/test', data,).then(
            res => {
                setTestLoading(false);
                if (res.status === 200) {
                    setTestResult({
                            message: "Test connector success",
                            type: "success"
                        }
                    );
                } else {
                    setTestResult({
                            message: "Test connector error",
                            type: "error"
                        }
                    );
                }
            }
        ).catch(
            err => {
                setTestResult({
                        message: "Test connector error",
                        type: "error"
                    }
                );
                setTestLoading(false);
            })
    };

    let providerConfig
    if (provider === "PRESTASHOP") {
        providerConfig = (
            <>
                <Field labelText="API Url" id="api-url-title">
                    <input
                        type="text"
                        value={connector.apiUrl}
                        onChange={onChange}
                        name="apiUrl"
                        id="api-url-title"
                    />
                </Field>
                <Field labelText="Auth" id="auth-title">
                    <input
                        type="password"
                        value={connector.auth}
                        onChange={onChange}
                        name="auth"
                        id="auth-title"
                    />
                </Field>
                <Field labelText="Time Zone" id="time-zone-title">
                    <input
                        type="text"
                        value={connector.timeZone}
                        onChange={onChange}
                        name="timeZone"
                        id="time-zone-title"
                    />
                </Field>
            </>
        )
    } else if (provider === "SHOPIFY") {
        providerConfig = (
            <>
                <Field labelText="API Url" id="api-url-title">
                    <input
                        type="text"
                        value={connector.apiUrl}
                        onChange={onChange}
                        name="apiUrl"
                        id="api-url-title"
                    />
                </Field>
                <Field labelText="Access Token" id="access-token-title">
                    <input
                        type="password"
                        value={connector.accessToken}
                        onChange={onChange}
                        name="accessToken"
                        id="access-token-title"
                    />
                </Field>
            </>
        )
    } else if (provider === "ONEBOX") {
        providerConfig = (
            <Field labelText="Client Secret" id="client-secret-title">
                <input
                    type="password"
                    value={connector.clientSecret}
                    onChange={onChange}
                    name="clientSecret"
                    id="client-secret-title"
                />
            </Field>
        )
    } else if (provider === "FANID") {
        providerConfig = (
            <></>
        )
    } else if (provider === "MAILCHIMP") {
        providerConfig = (
            <>
                <Field labelText="Server Prefix" id="server-prefix-title">
                    <input
                        type="text"
                        value={connector.serverPrefix}
                        onChange={onChange}
                        name="serverPrefix"
                        id="server-prefix-title"
                    />
                </Field>
                <Field labelText="User" id="user-title">
                    <input
                        type="text"
                        value={connector.user}
                        onChange={onChange}
                        name="user"
                        id="user-title"
                    />
                </Field>
                <Field labelText="Api Key" id="api-key-title">
                    <input
                        type="password"
                        value={connector.apiKey}
                        onChange={onChange}
                        name="apiKey"
                        id="apy-key-title"
                    />
                </Field>
                <Field labelText="Audience Id" id="audience-id-title">
                    <input
                        type="text"
                        value={connector.audienceId}
                        onChange={onChange}
                        name="audienceId"
                        id="audience-id-title"
                    />
                </Field>
            </>
        )
    } else if (provider === "QLIK") {
        providerConfig = (
            <>
                <Field labelText="Url" id="url-title">
                    <input
                        type="text"
                        value={connector.url}
                        onChange={onChange}
                        name="url"
                        id="url-title"
                    />
                </Field>
                <Field labelText="Auth" id="auth-title">
                    <input
                        type="text"
                        value={connector.auth}
                        onChange={onChange}
                        name="auth"
                        id="auth-title"
                    />
                </Field>
                <Field labelText="App Id" id="app-id-title">
                    <input
                        type="text"
                        value={connector.appId}
                        onChange={onChange}
                        name="appId"
                        id="app-id-title"
                    />
                </Field>
                <Field labelText="Fan Object Id" id="fan-object-id-title">
                    <input
                        type="text"
                        value={connector.fanObjectId}
                        onChange={onChange}
                        name="fanObjectId"
                        id="fan-object-id-title"
                    />
                </Field>
            </>
        )
    } else if (provider === "MAUTIC") {
        providerConfig = (
            <>
                <Field labelText="API Url" id="url-title">
                    <input
                        type="text"
                        value={connector.apiUrl}
                        onChange={onChange}
                        name="apiUrl"
                        id="url-title"
                    />
                </Field>
                <Field labelText="Username" id="username-title">
                    <input
                        type="text"
                        value={connector.username}
                        onChange={onChange}
                        name="username"
                        id="username-title"
                    />
                </Field>
                <Field labelText="Password" id="password-title">
                    <input
                        type="password"
                        value={connector.password}
                        onChange={onChange}
                        name="password"
                        id="password-title"
                    />
                </Field>
            </>
        )
    } else if (provider === "TWITTER") {
        providerConfig = (
            <>
                <Field labelText="Account Name" id="account-name-title">
                    <input
                        type="text"
                        value={connector.accountName}
                        onChange={onChange}
                        name="accountName"
                        id="account-name-title"
                    />
                </Field>
                <Field labelText="API Key" id="api-key-title">
                    <input
                        type="password"
                        value={connector.apiKey}
                        onChange={onChange}
                        name="apiKey"
                        id="api-key-title"
                    />
                </Field>
                <Field labelText="API Key Secret" id="api-key-secret-title">
                    <input
                        type="password"
                        value={connector.apiKeySecret}
                        onChange={onChange}
                        name="apiKeySecret"
                        id="api-key-secret-title"
                    />
                </Field>
                <Field labelText="Access Token" id="access-token-title">
                    <input
                        type="password"
                        value={connector.accessToken}
                        onChange={onChange}
                        name="accessToken"
                        id="access-token-title"
                    />
                </Field>
                <Field labelText="Access Token Secret" id="access-token-secret-title">
                    <input
                        type="password"
                        value={connector.accessTokenSecret}
                        onChange={onChange}
                        name="accessTokenSecret"
                        id="access-token-secret-title"
                    />
                </Field>
            </>
        )
    } else if (provider === "SHOPGOOGLESHEETS") {
        providerConfig = (
            <>
                <Field labelText="Customer Sheet ID" id="customer-sheet-id-secret-title">
                    <input
                        type="text"
                        value={connector.customerSheetId}
                        onChange={onChange}
                        name="customerSheetId"
                        id="customer-sheet-id-secret-title"
                    />
                </Field>
                <Field labelText="Order Sheet ID" id="order-sheet-id-secret-title">
                    <input
                        type="text"
                        value={connector.orderSheetId}
                        onChange={onChange}
                        name="orderSheetId"
                        id="order-sheet-id-secret-title"
                    />
                </Field>
                <Field labelText="Order Item Sheet ID" id="order-item-sheet-id-secret-title">
                    <input
                        type="text"
                        value={connector.orderItemSheetId}
                        onChange={onChange}
                        name="orderItemSheetId"
                        id="order-item-sheet-id-secret-title"
                    />
                </Field>
            </>
        )
    } else if (provider === "TICKETINGGOOGLESHEETS") {
        providerConfig = (
            <>
                <Field labelText="Customer Sheet ID" id="customer-sheet-id-secret-title">
                    <input
                        type="text"
                        value={connector.customerSheetId}
                        onChange={onChange}
                        name="customerSheetId"
                        id="customer-sheet-id-secret-title"
                    />
                </Field>
                <Field labelText="Ticket Sheet ID" id="ticket-sheet-id-secret-title">
                    <input
                        type="text"
                        value={connector.ticketSheetId}
                        onChange={onChange}
                        name="ticketSheetId"
                        id="ticket-sheet-id-secret-title"
                    />
                </Field>
            </>
        )
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="Name" id="name-title">
                    <input
                        type="text"
                        value={connector.name}
                        onChange={onChange}
                        name="name"
                        id="name-title"
                    />
                </Field>
                <Field labelText="Description" id="description-title">
                    <input
                        type="text"
                        value={connector.description}
                        onChange={onChange}
                        name="description"
                        id="description-title"
                    />
                </Field>
                <Field labelText="Provider" id="provider">
                    <Select
                        options={options} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                        }),
                    }}
                        onChange={(value) => setProvider(value.value)}
                        value={provider.value}
                        placeholder="Select provider"
                        name="provider"
                    />
                </Field>
                <Field labelText="Enabled" id="enabled-title">
                    <input
                        type="checkbox"
                        value={connector.enabled}
                        onChange={onChange}
                        name="enabled"
                        id="enabled-title"
                    />
                </Field>
                <Field labelText="Auto Sync" id="auto-sync-title">
                    <input
                        type="checkbox"
                        value={connector.autoSync}
                        onChange={onChange}
                        name="autoSync"
                        id="auto-sync-title"
                    />
                </Field>
                <Field labelText="Interval" id="interval-title">
                    <input
                        type="number"
                        value={connector.interval}
                        onChange={onChange}
                        name="interval"
                        id="interval-title"
                    />
                </Field>
                {providerConfig}
                <div>
                    <Button loading={testLoading} type="button" onClick={testConnector.bind(this, addToast)}>
                        Test
                    </Button>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                <Message text={error} type="error"/>
                <Message text={testResult.message} type={testResult.type}/>
            </form>

        </>

    );
}

export default ConnectorForm;

import React, {useEffect, useState} from "react";
import axios from "axios";
import {useAuth} from "../../authContext";
import {SegmentContext} from "./SegmentContext";
import styled from "styled-components";
import SegmentItem from "./SegmentItem";
import AddSegment from "./AddSegment";
import {EventSourcePolyfill} from "event-source-polyfill";
import {API_URL} from "../../constants";

const SegmentContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`

let connectorMap = new Map();
let EventSource = EventSourcePolyfill;

function SegmentItems() {
    const auth = useAuth();
    const [segments, setSegments] = useState([]);
    const [connectors, setConnectors] = useState(null);
    const updateSegments = () => {
        axios.get('/marketing/' + auth.user.tenant + '/segments').then(
            res => {
                setSegments(res.data.items)
            },
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        const eventSource = new EventSource(API_URL + '/system/sse', {
            headers: {
                'Authorization': axios.defaults.headers.common['Authorization']
            }
        });
        eventSource.onmessage = (event) => {
            const eventData = event.data;
            if (eventData === 'SegmentUpdated' || eventData === 'SegmentDeleted') {
                updateSegments();
            }
        };
        axios.get('/integrations/' + auth.user.tenant + '/connectors?type=marketing').then(
            res => {
                res.data.items.map((connector) => {
                    connectorMap.set(connector.id, connector.name)
                })
                setConnectors(connectorMap);
            },
            err => {
                console.log(err)
            }
        )
        updateSegments();
        return () => {
            eventSource.close();
        };
    }, [])

    return (
        <SegmentContext.Provider value={updateSegments}>
            <AddSegment/>
            <SegmentContainer>
                {segments.map((segment) => (
                    <SegmentItem segment={segment} connectors={connectors}/>
                ))}
            </SegmentContainer>
        </SegmentContext.Provider>
    );
}

export default SegmentItems;
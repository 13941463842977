import React, {useState} from "react";
import axios from "axios";
import styled from "styled-components";
import {useAuth} from "../../authContext";
import AddFan from "./AddFan";
import UploadFansCSV from "./UploadFansCSV";
import FansTable from "./FansTable";
import {FanContext} from "./FanContext";

const FlexContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
    flex-grow: 0;
    width: auto;
`;


function Fans() {
    const auth = useAuth();
    const [fans, setFans] = useState([]);
    const [pagination, setPagination] = useState({
        offset: 0,
        limit: 10,
        moreItems: false,
        totalItems: 0
    });

    const updateFans = (offset = 0, limit = 10) => {
        axios
            .get('fan/' + auth.user.tenant + '/fans?limit=' + limit + '&offset=' + offset)
            .then((res) => {
                setFans(res.data.items);
                setPagination({
                    offset: res.data.offset,
                    limit: res.data.limit,
                    moreItems: res.data.moreItems,
                    totalItems: res.data.totalItems
                })
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <FanContext.Provider value={updateFans}>
            <FlexContainer>
                <AddFan/>
                <UploadFansCSV/>
            </FlexContainer>
            <FansTable fans={fans} pagination={pagination}/>
        </FanContext.Provider>
    );
}

export default Fans;

import Page from "../ui/Page";
import MembershipsComponent from "../components/memberships/Memberships";

function Memberships() {
    return (
        <Page title="Memberships">
            <MembershipsComponent/>
        </Page>
    );
}

export default Memberships;
function timestampToDate(date) {
    if (!date) {
        return null;
    }
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export default timestampToDate;

import {Button, Field, Message} from "../../../ui/core";

function InviteUserForm({
                      loading, onSubmit,
                      data,
                      setData,
                      error,
                  }) {

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <Field labelText="Email" id="email-title">
                    <input
                        type="email"
                        value={data.email}
                        onChange={onChange}
                        name="email"
                        id="email-title"
                    />
                </Field>
                <div>
                    <Button loading={loading} type="submit">
                        Save
                    </Button>
                </div>
                <Message text={error} type="error"/>
            </form>

        </>
    );
}

export default InviteUserForm;

import styled from 'styled-components';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../authContext";
import axios from "axios";
import {Message} from "../ui/core";
import Select from 'react-select';

const LogoStyle = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    padding-top: 5%;
`

const LoginFormStyle = styled.div`
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    width: 30%;
    height: 95%;
    margin: 40px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.4rem;
    max-width: 100%;
`

const RowSelect = styled.div`
    width: 80%;
    height: 100%;
`

const RowInput = styled.input`
    & {
        width: 80%;
        box-sizing: border-box;
        border: none;
        font-size: 0.95rem;
        padding-left: 1.5rem;
        padding-bottom: 1rem;
        box-shadow: inset 0px -3px 0px 0px rgba(9, 8, 8, 0.2);
        transition: box-shadow 0.2s ease-in;
        -webkit-appearance: none;
        border-radius: 0px;
    }

    &:focus {
        box-shadow: inset 0px -3px 0px 0px var(--color-primary);
        outline: none;
    }

    &::-webkit-input-placeholder {
        opacity: 1;
        transition: opacity 0.25s ease-out;
        color: rgba(9, 5, 5, 0.34);
    }

    &:hover::-webkit-input-placeholder,
    &:focus::-webkit-input-placeholder {
        opacity: 0;
    }
`

const RowLabel = styled.label`
    align-self: start;
    padding-left: 4.5rem;
    padding-bottom: 0.5rem;
    color: #555;
    font-size: 1rem;
`

const RowButton = styled.button`
    & {
        border-radius: 25px;
        width: 80%;
        height: 40px;
        font-size: 1.3rem;
        color: white;
        font-weight: 700;
        background: linear-gradient(90deg, var(--color-secondary) 0%, var(--color-secondary-light) 100%);
        border: 2px solid;
        border-color: var(--color-primary);
        cursor: pointer;
        transition: opacity 0.25s ease-out;
        margin-top: 1%;
    }

    &:hover {
        opacity: 0.8;
    }
`

const SignUpStyle = styled.div`
    text-align: center;
    padding-top: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
`

export const CreateAccountForm = () => {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const [country, setCountry] = useState('none')
    const [sport, setSport] = useState('none')
    const [countries, setCountries] = useState([])
    const [sports, setSports] = useState([])
    const [register, setRegister] = useState({
        name: "",
        description: "",
        username: "",
        country: "",
        sport: "",
        password: "",
    });
    useEffect(() => {
        axios.get('system/countries').then(
            res => {

                let options = []
                res.data.countries.forEach(
                    element => options.push({value: element.name, label: element.flag + ' ' + element.name})
                );
                setCountries(options)
            },
            err => {
                console.log(err)
            }
        )

        axios.get('system/sports').then(
            res => {
                let options = []
                res.data.sports.forEach(
                    element => options.push({value: element.id, label: element.icon + ' ' + element.name})
                );
                setSports(options)
            },
            err => {
                console.log(err)
            }
        )

    }, [])

    const [error, setError] = useState(null);
    const auth = useAuth();

    useEffect(() => {
        if (auth.user) history.replace(location.state ? location.state.from : '/');
    }, [auth.user])

    const onChange = (e) => {
        setRegister((prevRegister) => ({
            ...prevRegister,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        register.country = country
        register.sport = sport
        e.preventDefault();
        await axios.post('/accounts/create', register, {headers: {'Authorization': 'Bearer ' + params.token}}).then(async (resp) => {
            if (resp.status === 200) {
                await auth.signIn(resp.data.email, register.password);
            } else {
                setError(resp.data.error.details.message);
            }
        }).catch(() => {
            setError("Error registering new account");
        });
    }

    return (
        <>
            <Message text={error} type="error"/>
            <LoginFormStyle>
                <form onSubmit={handleSubmit}>
                    <FormHeader title="Login"/>
                    <Form register={register} onChange={onChange} country={country} setCountry={setCountry}
                          sport={sport} setSport={setSport}
                          countries={countries} sports={sports}/>
                    <SignIn/>
                </form>
            </LoginFormStyle>
        </>
    )
}

const FormHeader = () => (
    <a href={'/'}>
        <LogoStyle src={'logo.png'}/>
    </a>);

const Form = props => {
    return (
        <div>
            <FormInput value={props.register.name}
                       onChange={props.onChange}
                       description="Organization name"
                       placeholder="Enter your organization name"
                       type="text"
                       name="name"
                       maxLength="30"/>
            {/*<FormInput value={props.register.description}*/}
            {/*           onChange={props.onChange}*/}
            {/*           description="Description"*/}
            {/*           placeholder="Enter your description"*/}
            {/*           type="text"*/}
            {/*           name="description"*/}
            {/*           maxLength="100"/>*/}
            <FormInput value={props.register.username}
                       onChange={props.onChange}
                       description="Username"
                       placeholder="Enter your username"
                       type="text"
                       name="username"
                       maxLength="10"/>
            <FormInput value={props.register.password}
                       onChange={props.onChange}
                       description="Password"
                       placeholder="Enter your password"
                       type="password" name="password"/>
            <Row>
                <RowLabel>Country</RowLabel>
                <RowSelect>
                    <Select
                        options={props.countries} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                        }),
                    }}
                        onChange={(value) => props.setCountry(value.value)}
                        value={props.country.value}
                        placeholder="Select your country"
                        name="country"
                    />
                </RowSelect>
            </Row>
            <Row>
                <RowLabel>Sport</RowLabel>
                <RowSelect>
                    <Select
                        options={props.sports} styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                        }),
                    }}
                        onChange={(value) => props.setSport(value.value)}
                        value={props.sport.value}
                        placeholder="Select your sport"
                        name="sport"
                    />
                </RowSelect>
            </Row>

            <FormButton title="Sign Up"/>
        </div>
    )
};

const FormButton = props => (
    <Row>
        <RowButton type="submit">{props.title}</RowButton>
    </Row>
);

const FormInput = props => (
    <Row>
        <RowLabel>{props.description}</RowLabel>
        <RowInput type={props.type}
                  placeholder={props.placeholder}
                  value={props.value}
                  onChange={props.onChange}
                  name={props.name}
                  id={props.name}
                  maxLength={props.maxLength}
        />
    </Row>
);

const SignIn = () => (
    <SignUpStyle>
        <label>Already registered? </label>
        <a href={'/login'}>Sign In</a>
    </SignUpStyle>
);

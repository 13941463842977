import Page from "../ui/Page";
import FansComponent from "../components/fans/FansComponent";

function Fans() {
    return (
        <Page title="Settings">
            <FansComponent/>
        </Page>
    );
}

export default Fans;
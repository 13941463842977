import {useState} from "react";
import Button from "../../ui/core/Button";
import { ToasterContext } from "../../ui/toasterCtx/ToasterContext";
import axios from "axios";
import { useAuth } from "../../authContext";
import ImportFanModal from "./ImportFanModal";
import ImportFanForm from "./ImportFanForm";

function UploadFansCSVModal() {
    const auth = useAuth();
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [error, setError] = useState(null);

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async (addToast, e) => {
        e.preventDefault();
        if (!selectedFile) {
            setError("Please select a file.");
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const res = await axios.post(
                `fan/${auth.user.tenant}/system-fans/upload-csv`,
                formData
            );
            if (res.status === 200) {
                addToast({ text: "Fans uploaded successfully", type: "success" });
                setIsModal(false);
                setSelectedFile(null);
            } else {
                setError("An error occurred while trying to import fans");
            }
        } catch (err) {
            setError("An error occurred while trying to import fans");
        } finally {
            setLoading(false);
        }
    };

    const modalShow = (v) => setIsModal(v);

    return (
        <ToasterContext.Consumer>
            {({ addToast }) => (
                <>
                    <div>
                        <Button onClick={modalShow.bind(this, true)}>Import</Button>
                        <ImportFanModal
                            title="Import fans"
                            show={isModal}
                            close={modalShow.bind(this, false)}
                        >
                            <ImportFanForm
                                addToast={addToast}
                                loading={loading}
                                error={error}
                                onFile={handleFileInputChange}
                                onSubmit={(e) => handleUpload(addToast, e)}
                            />
                        </ImportFanModal>
                    </div>
                </>
            )}
        </ToasterContext.Consumer>
    );
}

export default UploadFansCSVModal;

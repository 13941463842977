import {Button, Field, Message} from "../../ui/core";
import React from "react";
import timestampToDate from "../../functions/date";  // Para formato "YYYY-MM-DD"
import timestampToDateTime from "../../functions/dateTimeFormat";  // Para formato "YYYY-MM-DDTHH:MM"

function EditForm({
                      loading,
                      onSubmit,
                      info,
                      setInfo,
                      error,
                  }) {

    const onChange = (e) => {
        const {name, value, type, checked} = e.target;
        const newValue = type === 'checkbox' ? checked : type === 'number' ? parseInt(value, 10) : value;
        setInfo({
            ...info,
            [name]: newValue,
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <Field labelText="First Name" id="first-name">
                <input
                    type="text"
                    value={info.firstName}
                    onChange={onChange}
                    name="firstName"
                    id="first-name"
                />
            </Field>
            <Field labelText="Last Name" id="last-name">
                <input
                    type="text"
                    value={info.lastName}
                    onChange={onChange}
                    name="lastName"
                    id="last-name"
                />
            </Field>
            <Field labelText="Birthday" id="birthday-title">
                <input
                    type="date"
                    value={timestampToDate(info.birthDay)}
                    onChange={onChange}
                    name="birthDay"
                    id="birthday-title"
                />
            </Field>
            <Field labelText="Signed up at" id="signed-up-title">
                <input
                    type="datetime-local"
                    value={timestampToDateTime(info.signedUpAt)}
                    onChange={onChange}
                    name="signedUpAt"
                    id="signed-up-title"
                />
            </Field>
            <div>
                <Button loading={loading} type="submit">
                    Save
                </Button>
            </div>
            <Message text={error} type="error"/>
        </form>
    );
}

export default EditForm;
